<template>
  <div class="rounded-md bg-green-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-green-800">
          Welcome Beta user!
        </h3>
        <div class="mt-2 text-sm text-green-700">
          <p>
            Thank you for being an early adopter! Please <router-link to="/support" class="text-indigo-700 font-medium">contact us</router-link> for support.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from '@heroicons/vue/solid'

export default {
  components: {
    ExclamationIcon,
  },
}
</script>
