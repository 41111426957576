<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <div class="py-5 border-b border-gray-700 sm:flex sm:items-center sm:justify-between">
      <h1 class="text-xl leading-6 font-medium text-gray-900 grid justify-items-center">
        Your blockbase.dev emails
      </h1>
      <div class="mt-3 sm:mt-0 sm:ml-4 grid justify-items-center">
        <button v-if="addresses.length < $auth.user.value.plan.address_quota"
                @click="gotoClaim"
                type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
          Claim new email
        </button>
      </div>
    </div>
    <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 py-8">
      <li v-for="address in addresses" :key="address.email" class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1 truncate">
            <div class="flex items-center space-x-3">
              <h3 class="text-gray-900 text-sm font-medium truncate">{{ address.address }}@blockbase.dev</h3>
              <span
                v-if="address.connected && address.db_id"
                class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full"
                >
                Connected
              </span>
              <span v-else-if="address.connected" class="flex-shrink-0 inline-block px-2 py-0.5 text-red-800 text-xs font-medium bg-red-100 rounded-full">
                No DB Connected 
              </span>
              <span v-else class="flex-shrink-0 inline-block px-2 py-0.5 text-red-800 text-xs font-medium bg-red-100 rounded-full">
                Not Connected
              </span>
            </div>
            <div v-if="address.connected" class="mt-4">
              <span class="mx-4 mt-1 text-gray-700 text-sm truncate">{{ address.workspace_name }}&nbsp;
                <span v-if="address.db_name" class="text-gray-500">{{ address.db_name }}</span>
              </span>
            </div>
          </div>
          <img
            v-if="address.connected"
            class="w-10 h-10 bg-gray-300 flex-shrink-0"
            :src="address.workspace_icon"
            alt=""
          />
        </div>
        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="w-0 flex-1 flex" v-if="address.connected && address.db_id">
              <a
                :href="`mailto:${address.address}@blockbase.dev`"
                target="_blank"
                class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                >
                <MailIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span class="ml-3">Test Email</span>
              </a>
            </div>
            <div class="-ml-px w-0 flex-1 flex" v-if="address.connected && address.db_id">
              <router-link :to="'/connect/' + address.address" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                <PencilIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span class="ml-3">Edit</span>
              </router-link>
            </div>
            <div class="-ml-px w-0 flex-1 flex" v-else>
              <router-link :to="'/connect/' + address.address" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                <LightningBoltIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                <span class="ml-3">Connect</span>
              </router-link>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { MailIcon, PencilIcon, LightningBoltIcon } from '@heroicons/vue/solid'

export default {
  props: {
    addresses: Array
  },
  data () {
    return {
    }
  },
  components: {
    MailIcon,
    PencilIcon,
    LightningBoltIcon 
  },
  methods: {
    gotoClaim () {
      this.$router.push({ path: '/claim' }) 
    }
  }
}
</script>
