<template>
  <div class="home">
    <div class="max-w-7xl mx-auto pb-4 px-12">
      <Intro />
    </div>
    <div v-if="loadingState === 'loading'" class="flex justify-center">
      <h3 class="bg-yellow-50 p-4 mb-8 max-w-lg rounded">
        Loading...
        <svg class="mr-2 animate-spin inline h-5 w-5 text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </h3>
    </div>
    <div v-else-if="loadingState === 'error'" class="flex justify-center font-bold">
      <h3 class="bg-red-100 p-4 mb-8 max-w-lg rounded">
        Error connecting to the server.
        <br>
        Please reload to try again, or rest assured we will investigate and follow up in email.
        ❤️
      </h3>
    </div>
    <Addresses v-else :addresses="addresses" />
    <Activity />
  </div>
</template>

<script>
import Addresses from '@/components/Addresses.vue'
import Activity from '@/components/Activity.vue'
import Intro from '@/components/Intro.vue'
import { gql } from '@apollo/client/core'
import * as Sentry from '@sentry/browser'

export default {
  name: 'Home',
  data () {
    return {
      addresses: [],
      loadingState: true,
    }
  },
  components: {
    Addresses,
    Activity,
    Intro,
  },
  async mounted() {
    this.getAddresses()
  },
  methods: {
    async getAddresses() {
      this.loadingState = 'loading'
      var result = null
      try{
        result = await this.$apollo.query({
          query: gql`query { addresses: api_e2n_addresses(order_by: {address: asc}) { address, connected, token_failed, db_id, db_name, workspace_icon, workspace_name } }`,
          context: { headers: { authorization: 'Bearer ' + this.$auth.apitoken.value } }
        })
      } catch (err) {
        this.loadingState = 'error'
        Sentry.captureException(new Error(`Dashboard addresses GQL: ${err}`))
        return
      }
      if (result.errors) {
        this.loadingState = 'error'
        Sentry.captureException(new Error(`Dashboard addresses GQL result: ${result.errors}`))
        return
      }

      this.addresses = result.data.addresses
      if (this.addresses.length < 1) {
        this.$router.push({ path: '/claim' })
      }
      this.loadingState = 'ready'
    }
  }
};
</script>
