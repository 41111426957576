<template>
  <div>
    <div class="py-5 border-t border-gray-700 sm:flex sm:items-center sm:justify-between">
      <h1 class="text-xl leading-6 font-medium text-gray-900 grid justify-items-center">
        Recent activity
      </h1>
    </div>
    <div v-if="loadingState === 'loading'" class="bg-yellow-50 p-4 rounded">
      <svg class="mr-2 animate-spin inline h-5 w-5 font-bold text-indigo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      Loading...
    </div>
    <div v-else-if="loadingState === 'error'" class="bg-red-100 p-4 font-bold">
      Error loading your recent activity... please try again later.
    </div>
    <div v-else-if="activity.length < 1" class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <p class="text-sm text-yellow-700">
            You haven't sent any emails yet - go ahead and send one now!
          </p>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block max-w-7xl sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    When
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    From
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    To
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Subject
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(act, actIdx) in activity" :key="act.created" :class="actIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                  <td class="px-6 py-2 whitespace-nowrap text-xs font-medium text-gray-900">
                    {{ act.when }}
                  </td>
                  <td class="px-6 py-2 whitespace-nowrap text-xs text-gray-500">
                    {{ act.fromaddress }}
                  </td>
                  <td class="px-6 py-2 whitespace-nowrap text-xs text-gray-500">
                    {{ act.toaddress }}@blockbase.dev
                  </td>
                  <td class="px-6 py-2 whitespace-nowrap text-xs text-gray-500">
                    <span class="truncate">
                      {{ act.subject }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { gql } from '@apollo/client/core'
import { ExclamationIcon } from '@heroicons/vue/solid'
import { DateTime } from "luxon"

export default {
  data () {
    return {
      activity: [],
      loadingState: 'loading',
    }
  },
  components: {
    ExclamationIcon,
  },
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh () {
      try {
        var result = await this.$apollo.query({
          query: gql`query { api_e2n_usage(limit: 20, order_by: {created: desc}) {
            created fromaddress subject toaddress
          } }`,
          context: { headers: { authorization: 'Bearer ' + this.$auth.apitoken.value } }
        })
        for (var act of result.data.api_e2n_usage) {
          act.when = DateTime.fromISO(act.created, { zone: "UTC"}).toLocal().toLocaleString(DateTime.DATETIME_MED)
        }
        this.activity = result.data.api_e2n_usage
        this.loadingState = 'ready'
      } catch (err) {
        this.loadingState = 'error'
        Sentry.captureException(new Error(`Activity GQL: ${err}`))
      }
    }
  }
}
</script>
